import React, { useEffect } from 'react';
import ReactGA from "react-ga4";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
 
import WhatsApp from '../Widgets/WhatsAppWidget/WhatsApp';

import "./Eventos.scss";
import SliderDecoraciones from '../Sliders/SliderDecoraciones';

import videoHome from "../assets/videos/eventos.mp4";

const Eventos = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: "Eventos" });
  }, [])

  return (
    <div>
      <Header className="eventos-header"/>

      <div className='eventos-banner-area'>
        <div className='eventos-content-area'>
          <div className='eventos-content'>
            <h1>Eventos y mas</h1>
          </div>

          <video className='background' muted autoPlay loop src={videoHome}/>
        </div>
      </div>

      <div className='content'>
        <div className='eventos-content'>
          <div data-aos="fade-up" className='content-title'>
            <h1>Decoraciones</h1>
            <p>Decoraciones para todo tipo de evento</p>
            <span className='line'></span>
          </div>

          <SliderDecoraciones/>
        </div>
      </div>

      <div>
        
      </div>
      
      <WhatsApp/>
      <Footer/>
    </div>
  );
};

export default Eventos;