import React from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import { Zoom, Pagination, Navigation, Autoplay, Thumbs, FreeMode } from "swiper";

import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "swiper/css/thumbs";

import "./sliderStyles.scss";

const SliderPostres = () => {
  const slides = [
    {
      id: 1,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658418326/postres/lemon_pie_postre_em0df9.jpg"
    },
    {
      id: 2,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658418329/postres/algo_con_merengue_arriva_eo5zwg.jpg"
    },
    {
      id: 3,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658418341/postres/Mini_cheesecake_de_maracuya_gqpvwe.jpg"
    },
    {
      id: 4,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658418342/postres/Mini_chocotorta_fhcwbx.jpg"
    },
    {
      id: 5,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658418342/postres/mini_postre_oreo_2_fwrdsl.jpg"
    },
    {
      id: 6,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658418342/postres/Mini_cheesecake_utrghy.jpg"
    },
    {
      id: 7,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658418353/postres/mini_postre_dxoad5.jpg"
    },
    {
      id: 8,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658418369/postres/Torta_amarilla_verde_y_roja_2_leghmv.jpg"
    },
    {
      id: 9,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658418380/postres/Torta_amarilla_verde_y_roja_3_wapc7c.jpg"
    },
    {
      id: 10,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658418391/postres/torta_cheescake_de_frambuesa_2_sq9yba.jpg"
    },
    {
      id: 11,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658418393/postres/torta_cheescake_de_frambuesa_3_gny7iz.jpg"
    },
    {
      id: 12,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658418400/postres/torta_de_chocolate_con_frambuesas_y_coso_de_chocolate_2_kzjimb.jpg"
    },
    {
      id: 13,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658418403/postres/torta_de_chocolate_con_frambuesas_y_coso_de_chocolate_3_fhl1q1.jpg"
    },
    {
      id: 14,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658418410/postres/Torta_brownie_yxuco5.jpg"
    },
    {
      id: 15,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658418414/postres/torta_cheescake_de_frambuesa_uamvqx.jpg"
    },
    {
      id: 16,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658418421/postres/Torta_con_frutillas_ktdm7s.jpg"
    },
    {
      id: 17,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658418475/postres/torta_maracuya_xfl1g6.jpg"
    },
    {
      id: 18,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658418485/postres/Brownies_v24uiv.jpg"
    },
    {
      id: 19,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658418488/postres/Shots_de_chocolate_xk17yo.jpg"
    },
    {
      id: 20,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658418488/postres/Shots_de_Chocolina_ogk6vg.jpg"
    },
    {
      id: 21,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658418489/postres/Shots_de_frutilla_clyjiy.jpg"
    }
  ];

  return (
<div className='slider'>
      <Swiper
        style={{
          "--swiper-navigation-color": "#460874",
          "--swiper-pagination-color": "#fff",
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
          pauseOnMouseEnter: true
        }}
        spaceBetween={10}
        loop={true}
        navigation={true}
        zoom={true}
        modules={[Zoom, Navigation, Pagination, Autoplay, FreeMode, Thumbs]}
        className="mySwiper"
      >
        {
          slides && slides.map((t) => (
            <SwiperSlide key={t.id}>
              <div className="swiper-zoom-container">
                <img
                  src={t.image}
                  alt=''
                  className='slider-image'
                />
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  );
};

export default SliderPostres;