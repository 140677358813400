import React, { useEffect } from 'react';
import ReactGA from "react-ga4";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import Aos from "aos";
import "aos/dist/aos.css";

// IMAGENES PROPUESTAS
//import img1 from "../assets/images/eventoHeader.png";

import WhatsApp from '../Widgets/WhatsAppWidget/WhatsApp';

// Sliders
import SliderTortas from '../Sliders/SliderTortas';
import SliderReviews from '../Sliders/SliderReviews';
import SliderCupcakes from '../Sliders/SliderCupcakes';
import SliderPostres from '../Sliders/SliderPostres';

import "./Home.scss";
import GoogleMaps from '../Widgets/GoogleMaps/GoogleMaps';

import videoHome from "../assets/videos/home.mp4";

const Home = () => {
  /*
  const propuestas = [
    {
      id: 1,
      imagen: img1,
      titulo: "Envios",
      texto: ""
    },
    {
      id: 2,
      imagen: img1,
      titulo: "Organizacion de Eventos",
      texto: ""
    },
    {
      id: 3,
      imagen: img1,
      titulo: "Piñatas",
      texto: ""
    },
    {
      id: 4,
      imagen: img1,
      titulo: "Desayunos",
      texto: ""
    },
    {
      id: 5,
      imagen: img1,
      titulo: "Cajas",
      texto: ""
    },
    {
      id: 6,
      imagen: img1,
      titulo: "Decoraciones",
      texto: ""
    }
  ];
  */

  useEffect(() => {
    Aos.init({duration: 1000});
    ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: "Inicio" });
    /*
    window.onload = () => {
      setLoading(false);

      let cant = 0;

      setTimeout(() => {
        setLoading(true)
      }, 6000);

      if (cant === 0 ) {
        window.scrollTo(0, document.querySelector(".App").scrollHeight);
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 1500);
      }

      setTimeout(() => {
        cant++;
        if (cant === 1 ) {
          window.scrollTo(0, document.querySelector(".App").scrollHeight);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 1500);
        }
      }, 3000);
    };
    */
  }, []);

  return (
    <div className='home'>
      <Header/>

      <div className='home-banner-area'>
        <div className='home-content-area'>
          <div className='home-content'>
            <h1>Bienvenidos a <br/> Mundo de la Dulzura</h1>
          </div>

          <video className='background' muted autoPlay loop src={videoHome}/>
        </div>
      </div>
      
      <div data-aos="fade-up" className='content' id='novedades'>
        <div className='home-content'>
          <div className='content-title'>
            <h1>Ultimas Novedades</h1>
            <p>Desayuno dia del amigo</p>
            <span className='line'></span>
          </div>
        </div>
      </div>

      {
        /*
                <div data-aos="fade-up" className='content'>
        <div className='home-content'>
          <div className='content-title'>
            <h1>Nuestras propuestas</h1>
            <p>Las mejores propuestas para vos!</p>
            <span className='line'></span>
          </div>
        </div>

        <div className='home-content-propuestas'>
          {
            propuestas && propuestas.map((p) => (
              <div className='propuesta-card' key={p.id}>
                <div className='propuesta-image'>
                  <img src={p.imagen} alt="evento"/>
                </div>

                <div className='propuesta-body'>
                  <h3>{p.titulo}</h3>
                  <p>{p.texto}</p>
                </div>
              </div>
            ))
          }
        </div>
      </div>
        */
      }

      <div className='content'>
        <div className='home-content'>
          <div data-aos="fade-up" className='content-title'>
            <h1>Nuestras Tortas</h1>
            <p>Hechas con la mejor calidad</p>
            <span className='line'></span>
          </div>

          <SliderTortas className="sliders-content"/>
        </div>
      </div>

      <div className='content'>
        <div className='home-content'>
          <div data-aos="fade-up" className='content-title'>
            <h1>Cupcakes</h1>
            <p>Las mejores propuestas para vos!</p>
            <span className='line'></span>
          </div>

          <SliderCupcakes/>
        </div>
      </div>

      <div className='content'>
        <div className='home-content'>
          <div data-aos="fade-up" className='content-title'>
            <h1>Postres</h1>
            <p>Las mejores propuestas para vos!</p>
            <span className='line'></span>
          </div>

          <SliderPostres/>
        </div>
      </div>

      <div data-aos="fade-up" className='content'>
        <div className='home-content-end'>
          <div className='content-title responsive'>
            <h1>Un mundo de dulzura</h1>
            <p>Podras pasar a retirar calquiera de nuestros productos en <a href='https://goo.gl/maps/En6Gd42g6FZzUjyS8' target='_blank' rel='noreferrer' className='adress'>Montes de Oca 250</a></p>
            <span className='line'></span>
          </div>

          <GoogleMaps/>

          <div className='content-title'>
            <h1>Un mundo de dulzura</h1>
            <p>Podras pasar a retirar calquiera de nuestros productos en <a href='https://goo.gl/maps/En6Gd42g6FZzUjyS8' target='_blank' rel='noreferrer' className='adress'>Montes de Oca 250</a></p>
            <span className='line'></span>
          </div>
        </div>
      </div>

      <WhatsApp/>
      <Footer/>
    </div>
  );
};

export default Home;