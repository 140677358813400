import React from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import { Zoom, Pagination, Navigation, Autoplay, Thumbs, FreeMode } from "swiper";

import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "swiper/css/thumbs";

import "./sliderStyles.scss";

const SliderDecoraciones = () => {
  const slides = [
    {
      id: 1,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659455217/decoraciones/Decoracion_Boda_1_y81kvc.jpg"
    },
    {
      id: 2,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659455219/decoraciones/Decoracion_Boda_3_zy9y7s.jpg"
    },
    {
      id: 3,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659455224/decoraciones/Decoracion_evento_plateado_y_celeste_wmlutg.jpg"
    },
    {
      id: 4,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659455222/decoraciones/Decoracion_Evento_Blanco_v9knfc.png"
    },
    {
      id: 5,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659970805/decoraciones/decoracion1_uv5dmi.jpg"
    },
    {
      id: 6,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659970813/decoraciones/decoracion17_cgv15o.jpg"
    },
    {
      id: 7,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659970817/decoraciones/decoracion12_agmkjj.jpg"
    },
    {
      id: 8,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659970845/decoraciones/decoracion2_twsooa.jpg"
    },
    {
      id: 9,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659970867/decoraciones/decoracion3_ywqpfg.jpg"
    },
    {
      id: 10,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659970867/decoraciones/decoracion7_cxbft5.jpg"
    },
    {
      id: 11,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659970867/decoraciones/decoracion4_lzcvb8.jpg"
    },
    {
      id: 12,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659970867/decoraciones/decoracion5_gaczru.jpg"
    },
    {
      id: 13,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659970867/decoraciones/decoracion6_u5d6oc.jpg"
    },
    {
      id: 14,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659970892/decoraciones/decoracion16_wttl66.jpg"
    },
    {
      id: 15,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659970892/decoraciones/decoracion13_gpyc3o.jpg"
    },
    {
      id: 16,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659970892/decoraciones/decoracion14_golbhw.jpg"
    },
    {
      id: 17,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659970892/decoraciones/decoracion15_osjnkh.jpg"
    },
    {
      id: 18,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659970908/decoraciones/decoracion18_uunkei.jpg"
    },
    {
      id: 19,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659970921/decoraciones/decoracion10_gotqlh.jpg"
    },
    {
      id: 20,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659970921/decoraciones/decoracion8_y65u5a.jpg"
    },
    {
      id: 21,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659970921/decoraciones/decoracion9_fjxran.jpg"
    },
    {
      id: 22,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659970921/decoraciones/decoracion11_qdwmbz.jpg"
    }
  ];

  return (
    <div className='slider'>
      <Swiper
        style={{
          "--swiper-navigation-color": "#460874",
          "--swiper-pagination-color": "#fff",
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
          pauseOnMouseEnter: true
        }}
        spaceBetween={10}
        loop={true}
        navigation={true}
        zoom={true}
        modules={[Zoom, Navigation, Pagination, Autoplay, FreeMode, Thumbs]}
        className="mySwiper"
      >
        {
          slides && slides.map((t) => (
            <SwiperSlide key={t.id}>
              <div className="swiper-zoom-container">
                <img
                  src={t.image}
                  alt=''
                  className='slider-image'
                />
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  );
};

export default SliderDecoraciones;