import React, { useEffect } from 'react';
import ReactGA from "react-ga4";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import WhatsApp from '../Widgets/WhatsAppWidget/WhatsApp';

import "./Piñatas.scss";
import SliderPinatas from '../Sliders/SliderPinatas';

import videoHome from "../assets/videos/pinatas.mp4";

const Piñatas = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: "Piñatas" });
  }, []);

  return (
    <div>
      <Header/>

      <div className='pinatas-banner-area'>
        <div className='pinatas-content-area'>
          <div className='pinatas-content'>
            <h1>Piñatas</h1>
          </div>

          <video className='background' muted autoPlay loop src={videoHome}/>
        </div>
      </div>

      <div className='content'>
        <div className='pinatas-content'>
          <div data-aos="fade-up" className='content-title'>
            <h1>Piñatas</h1>
            <p>Nuestras piñatas hechas con amor</p>
            <span className='line'></span>
          </div>

          <SliderPinatas/>
        </div>
      </div>

      <WhatsApp/>
      <Footer/>
    </div>
  );
};

export default Piñatas;