import React from 'react'
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';
//import { MarkerF } from '@react-google-maps/api';

import icono from "../../assets/images/marker3.png";

import "./GoogleMaps.scss"

const center = {
    lat: -34.631506614508766,
    lng: -58.37627520525123,
}
  
const position = {
    lat: -34.631506614508766,
    lng: -58.37627520525123, 
}

const GoogleMaps = () => {
    const handleClick = () => {
        window.open("https://www.google.com.ar/maps/place/Mundo+de+la+Dulzura/@-34.6263323,-58.3751583,19z/data=!3m1!4b1!4m5!3m4!1s0x95bccbf0149a3c2b:0xabd6e22440460f5a!8m2!3d-34.6263334!4d-58.3746098")
    }
    
    return (
        <LoadScript
            googleMapsApiKey="AIzaSyB51yf_Q2oa7myoIz_Wnp3nYuxdDJxBg0E"
        >
            <GoogleMap
                mapContainerClassName='googleMap'
                center={center}
                zoom={15}
            >
                <Marker
                    icon={icono}
                    position={position}
                    onClick={handleClick}
                />
            </GoogleMap>
      </LoadScript>
    );
};

export default GoogleMaps;