import React from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import { Zoom, Pagination, Navigation, Autoplay, Thumbs, FreeMode } from "swiper";

import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "swiper/css/thumbs";

import "./sliderStyles.scss";

const SliderTortas = () => {
  const slides = [
    {
      id: 2,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416633/tortas/torta_amarilla_con_macarrones_j9lsuw.jpg"
    },
    {
      id: 3,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416603/tortas/Torta_15_a%C3%B1os_anali_2_zhikln.jpg"
    },
    {
      id: 4,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416618/tortas/Torta_amarilla_verde_y_roja_gkrswb.jpg"
    },
    {
      id: 5,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416621/tortas/torta_16_a%C3%B1os_2_azpqys.jpg"
    },
    {
      id: 6,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416627/tortas/Torta_arcoiris_i9huep.jpg"
    },
    {
      id: 7,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416640/tortas/Torta_con_chocolates_lqijlb.jpg"
    },
    {
      id: 8,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416645/tortas/Torta_con_Pesa_pdqudx.jpg"
    },
    {
      id: 9,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416647/tortas/Torta_corona_2_gjh2kz.jpg"
    },
    {
      id: 10,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416648/tortas/Torta_con_flores_blancas_tkh6ha.jpg"
    },
    {
      id: 11,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416664/tortas/Torta_Corona_zffozr.jpg"
    },
    {
      id: 12,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416664/tortas/Torta_de_boca_1_nagog1.jpg"
    },
    {
      id: 13,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416665/tortas/Torta_de_animalitos_con_carpa_antigua_blgtqj.jpg"
    },
    {
      id: 14,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416665/tortas/torta_de_15_fwha99.jpg"
    },
    {
      id: 15,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416665/tortas/Torta_de_Bendy_iowxkg.jpg"
    },
    {
      id: 16,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416665/tortas/torta_de_15_2_cnuvo9.jpg"
    },
    {
      id: 17,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416666/tortas/Torta_de_Autitos_erev8f.jpg"
    },
    {
      id: 18,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416672/tortas/Torta_de_boca_3_vdez4d.jpg"
    },
    {
      id: 19,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416676/tortas/Torta_de_boca_historia_z616kq.jpg"
    },
    {
      id: 20,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416677/tortas/Torta_de_boca_5_rrl2bw.jpg"
    },
    {
      id: 21,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416679/tortas/Torta_de_boca_con_botin_exbiir.jpg"
    },
    {
      id: 22,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416681/tortas/Torta_de_boda_skzeed.jpg"
    },
    {
      id: 23,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416691/tortas/torta_de_boda_3_qflagg.jpg"
    },
    {
      id: 24,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416691/tortas/torta_de_boda_7_xjewvn.jpg"
    },
    {
      id: 25,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416691/tortas/Torta_de_boda_4_etlyk4.jpg"
    },
    {
      id: 26,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416690/tortas/Torta_de_boda_2_db8j4h.jpg"
    },
    {
      id: 27,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416691/tortas/torta_de_boda_6_fvzefq.jpg"
    },
    {
      id: 28,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416692/tortas/torta_de_brawl_stars_jifklo.jpg"
    },
    {
      id: 29,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416692/tortas/torta_de_boda_5_vodrvn.jpg"
    },
    {
      id: 30,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416699/tortas/Torta_de_chocolate_con_chocolates_arriba_y_huevo_kinder_2_pd2rlv.jpg"
    },
    {
      id: 31,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416701/tortas/Torta_de_Corazon_iimxmx.jpg"
    },
    {
      id: 32,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416708/tortas/Torta_de_Huracan_kqaewh.jpg"
    },
    {
      id: 33,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416709/tortas/Torta_de_Frozen_h3eotj.jpg"
    },
    {
      id: 34,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416709/tortas/Torta_de_graduado_anzzrf.jpg"
    },
    {
      id: 35,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416709/tortas/Torta_de_Doctor_xwbilf.jpg"
    },
    {
      id: 36,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416716/tortas/Torta_de_mariposas_con_monta%C3%B1a_3_lcnbnc.jpg"
    },
    {
      id: 37,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416724/tortas/Torta_de_unicornio_1_kwgkav.jpg"
    },
    {
      id: 38,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416725/tortas/Torta_de_Masha_y_el_Oso_qwjovs.jpg"
    },
    {
      id: 39,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416726/tortas/Torta_de_Moto_fzyfxt.jpg"
    },
    {
      id: 40,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416727/tortas/Torta_de_River_lpvqnn.jpg"
    },
    {
      id: 41,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416729/tortas/Torta_de_sirena_ven81d.jpg"
    },
    {
      id: 42,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416736/tortas/Torta_Dragon_Ball_2_qlgzco.jpg"
    },
    {
      id: 43,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416743/tortas/torta_happy_birthday_morada_con_globo_dhhpw2.jpg"
    },
    {
      id: 44,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416746/tortas/Torta_Emilia_gvzelx.jpg"
    },
    {
      id: 45,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416752/tortas/Torta_oreo_dorada_whybev.jpg"
    },
    {
      id: 46,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416753/tortas/torta_sombrero_2_isock8.jpg"
    },
    {
      id: 47,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416753/tortas/Torta_playstation_g7rqsg.jpg"
    },
    {
      id: 48,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416753/tortas/Torta_semi_amarilla_con_flores_arriba_vz59yi.jpg"
    },
    {
      id: 49,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416753/tortas/Torta_Rosa_cl6z0z.jpg"
    },
    {
      id: 50,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416754/tortas/Torta_roja_con_mo%C3%B1o_blanco_arriba_y_puntos_blancos_wpb66y.jpg"
    },
    {
      id: 51,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416754/tortas/Torta_rosa_con_chocolates_vnpddw.jpg"
    },
    {
      id: 52,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416760/tortas/Torta_tematica_con_vino_2_qxvhmo.jpg"
    },
    {
      id: 53,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416779/tortas/Torta_bob_esponja_enzav5.png"
    },
    {
      id: 54,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416780/tortas/torta_rosa_con_macarons_2_kohvg9.png"
    },
    {
      id: 55,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416790/tortas/Torta_morada_feliz_cumplea%C3%B1os_snnjk7.png"
    },
    {
      id: 56,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416793/tortas/torta_postre_1_tdxczd.jpg"
    },
    {
      id: 57,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658416800/tortas/Torta_Shinobu_sola_Demon_Slayer_-_Kimetsu_no_yaiba_kgve9m.png"
    },
    {
      id: 58,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658417049/tortas/torta_river_fz7i4n.png"
    },
    {
      id: 59,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659977403/tortas/IMG_20220615_095110_qckz4n.jpg"
    },
    {
      id: 60,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659977445/tortas/IMG_20220612_175641_ddixpc.jpg"
    },
    {
      id: 61,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1659977481/tortas/IMG_20220612_170018_sfmxvp.jpg"
    }
  ];

  return (
    <div className='slider'>
      <Swiper
        style={{
          "--swiper-navigation-color": "#460874",
          "--swiper-pagination-color": "#fff",
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
          pauseOnMouseEnter: true
        }}
        spaceBetween={10}
        loop={true}
        navigation={true}
        zoom={true}
        modules={[Zoom, Navigation, Pagination, Autoplay, FreeMode, Thumbs]}
        className="mySwiper"
      >
        {
          slides && slides.map((t) => (
            <SwiperSlide key={t.id}>
              <div className="swiper-zoom-container">
                <img
                  src={t.image}
                  alt=''
                  className='slider-image'
                />
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  );
};

export default SliderTortas;