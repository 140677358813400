import React from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import { Zoom, Pagination, Navigation, Autoplay, Thumbs, FreeMode } from "swiper";

import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "swiper/css/thumbs";

import "./sliderStyles.scss";

const SliderCupcakes = () => {
  const slides = [
    {
      id: 1,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658417854/cupcakes/Cupcakes_morados_con_maripositas_ewxiet.png"
    },
    {
      id: 2,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658417910/cupcakes/muffins_desde_arriba_bdjuue.jpg"
    },
    {
      id: 3,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658418049/cupcakes/Cupcakes_ponwmk.png"
    },
    {
      id: 4,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658417909/cupcakes/muffins_de_animales_taixus.jpg"
    },
    {
      id: 5,
      image: "https://res.cloudinary.com/dk69jry82/image/upload/v1658417883/cupcakes/Postres_navidad_2_xtlbxe.jpg"
    }
  ];

  return (
    <div className='slider'>
      <Swiper
        style={{
          "--swiper-navigation-color": "#460874",
          "--swiper-pagination-color": "#fff",
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
          pauseOnMouseEnter: true
        }}
        spaceBetween={10}
        loop={true}
        navigation={true}
        zoom={true}
        modules={[Zoom, Navigation, Pagination, Autoplay, FreeMode, Thumbs]}
        className="mySwiper"
      >
        {
          slides && slides.map((t) => (
            <SwiperSlide key={t.id}>
              <div className="swiper-zoom-container">
                <img
                  src={t.image}
                  alt=''
                  className='slider-image'
                />
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  );
};

export default SliderCupcakes;