import React from "react";
import { Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";

import Home from "./components/Home/Home";
import Eventos from "./components/Eventos/Eventos";
import SobreNosotros from "./components/SobreNosotros/SobreNosotros";
import Piñatas from "./components/Pinatas/Piñatas";

const TRACKING_ID = "G-2JCZGWRLC4";
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/eventos" element={<Eventos/>} />
        <Route path="/sobrenosotros" element={<SobreNosotros/>} />
        <Route path="/pinatas" element={<Piñatas/>} />
      </Routes>
    </div>
  );
};

export default App;
