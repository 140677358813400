import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { FaTiktok } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaFacebook } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';

import { FaPhoneAlt } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import { FaMapMarkerAlt } from 'react-icons/fa';

import logo from '../assets/images/Logo.png';

import Aos from "aos";
import "aos/dist/aos.css";

import "./Footer.scss";

const Footer = () => {
  const scrollToUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    Aos.init({duration: 1000});
  })

  return (
    <footer className='footer'>
      <section className='information'>
        <section className='copyright'>
          <img src={logo}/>

          <div className='redes-sociales'>
            <a href='https://www.tiktok.com/@mundodeladulzura' target="_blank" rel='noreferrer'><FaTiktok/></a>
            <a href='https://www.instagram.com/mundo.dulzura' target="_blank" rel='noreferrer'><FaInstagram className='social'/></a>
            <a href='https://www.facebook.com/mundodeladulzura' target="_blank" rel='noreferrer'><FaFacebook/></a>
            <a href='https://www.linkedin.com/company/mundo-de-la-dulzura/' target="_blank" rel='noreferrer'><FaLinkedinIn/></a>
          </div>

          <p>Copyright © 2023 Mundo de la dulzura</p>

          <p className='programador'>Desarrollado por <a href="https://briansanchez.com.ar" target="_blank">Brian Sanchez</a> </p>
        </section>

        <div className='navegacion'>
          <h5>Navegacion</h5>
          <Link to="/" className='link' onClick={scrollToUp}>Inicio</Link>
          <Link to="/sobrenosotros" className='link' onClick={scrollToUp}>Sobre Nosotros</Link>
          <Link to="/eventos" className='link' onClick={scrollToUp}>Eventos</Link>
          <Link to="/pinatas" className='link' onClick={scrollToUp}>Piñatas</Link>
        </div>

        <div className='contacto'>
          <h5>Contacto</h5>
          <a href="https://api.whatsapp.com/send/?phone=5491136045221&text&app_absent=0" target="_blank" rel='noreferrer'> <FaPhoneAlt className='icon'/> <p>+54 11 3604-5221</p></a>
          <a href="https://goo.gl/maps/En6Gd42g6FZzUjyS8" target="_blank" rel='noreferrer'> <FaMapMarkerAlt className='icon'/> <p>Montes de Oca 250</p> </a>
          <a href="mailto:contacto@mundodeladulzura.com.ar" target="_blank" rel='noreferrer'> <FaEnvelope className='icon'/> <p>contacto@mundodeladulzura.com.ar</p> </a>
        </div>

        <div className='informacion-legal'>
          <h5>Informacion Legal</h5>
          <a href="http://qr.afip.gob.ar/?qr=SXiy7dUjrt14kpZVJur5PQ,," target="_F960AFIPInfo"><img src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg" alt='afip'/></a>
        </div>
      </section>
    </footer>
  );
};

export default Footer;