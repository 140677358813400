import React, { useEffect } from 'react';
import ReactGA from "react-ga4";

import { HiOutlineCake } from 'react-icons/hi';

import Aos from "aos";
import "aos/dist/aos.css";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import WhatsApp from '../Widgets/WhatsAppWidget/WhatsApp';

import videoHome from "../assets/videos/sobreNosotros.mp4";

import "./SobreNosotros.scss";

const SobreNosotros = () => {
  useEffect(() => {
    Aos.init({duration: 1000});
    ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: "Sobre Nosotros" });
  }, []);

  return (
    <div>
      <Header/>

      <div className='sobreNosotros-banner-area'>
        <div className='sobreNosotros-content-area'>
          <div className='sobreNosotros-content'>
            <h1>Sobre Nosotros</h1>
          </div>

          <video className='background' muted autoPlay loop src={videoHome}/>
        </div>
      </div>

      <div className='about-content'>        
        <div data-aos="fade-up" className='about-content-text'>
          <div className='cake-icon'>
            <HiOutlineCake/>
          </div>

          <p>
          Somos una empresa especializada en elaborar productos de la mejor calidad, combinando sólo las mejores materias primas y recetas únicas a manos de nuestro maestro pastelero, quien tiene una trayectoria de 15 años de experiencia en el arte de la pastelería.
          <br></br>
          <br></br>
          Nos enorgullece ofrecer las mejores opciones para que tu evento sea verdaderamente memorable y único. Ya sea que esté planeando una boda, un cumpleaños u otra ocasión especial, nos aseguramos de que cada detalle esté cuidadosamente considerado y preparado con el mayor cuidado y atención posible.
          <br></br>
          <br></br>
          En Mundo de la Dulzura, nos comprometemos con cada cliente para brindar la mejor atención posible. Nos aseguramos de que cada producto que elaboramos sea de la más alta calidad y cumpla con las expectativas de nuestros clientes, ya que somos conscientes de la importancia de esos momentos especiales en la vida de cada persona.
          <br></br>
          <br></br>
          Por eso, queremos que nuestros productos y nuestra dedicada atención estén presentes en esos momentos de felicidad y celebración, para hacer que esos recuerdos sean aún más dulces y especiales. En Mundo de la Dulzura, hacemos todo lo posible para crear experiencias inolvidables y satisfacer los paladares más exigentes.
          </p>

          <span className='line'></span>

          <div className='about-content-logo'>
            <h4 className='logo'>Mundo de la Dulzura</h4>
            <h4>P A S T E L E R I A</h4>
          </div>
        </div>
      </div>

      <WhatsApp/>
      <Footer/>
    </div>
  );
};

export default SobreNosotros;