import React, { useState } from 'react';

import { Link } from "react-router-dom";
import Logo from "../assets/images/logo.svg";

import { FaTiktok } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaFacebook } from 'react-icons/fa';

import "./Header.scss";
import BurguerButton from './BurgerButton/BurguerButton';

const Header = () => {
  const [header, setHeader] = useState(false);
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    // cuando esta true lo pasa a false y viceversa
    setClicked(!clicked);
  };

  const changeBackground = () => {
    if (window.scrollY >= 130) {
      setHeader(true);
    } else {
      setHeader(false);
    };
  };

  window.addEventListener("scroll", changeBackground);

  const scrollToUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <header className={header ? 'header active' : 'header'}>
      <div className='icons'>
        <a href='https://www.tiktok.com/@mundodeladulzura' target="_blank" rel='noreferrer'><FaTiktok/></a>
        <a href='https://www.instagram.com/mundo.dulzura' target="_blank" rel='noreferrer'><FaInstagram/></a>
        <a href='https://www.facebook.com/mundodeladulzura' target="_blank" rel='noreferrer'><FaFacebook/></a>
      </div>

      <div className={`responsive-menu ${clicked ? 'active' : ''}`}>
        <div className='center'>
          <div className='center-responsive-menu'>
            <Link to="/" className='link' onClick={scrollToUp}>INICIO</Link>
            <Link to="/sobrenosotros" className='link' onClick={scrollToUp}>SOBRE NOSOTROS</Link>
            <Link to="/eventos" className='link' onClick={scrollToUp}>EVENTOS</Link>
            <Link to="/pinatas" className='link' onClick={scrollToUp}>PIÑATAS</Link>
          </div>
        </div>
      </div>

      <div className='menu'>
        <BurguerButton clicked={clicked} handleClick={handleClick}/>

        <ul>
          <li onClick={scrollToUp}><Link to="/" className='link'>INICIO</Link></li>
          <li onClick={scrollToUp}><Link to="/sobrenosotros" className='link'>SOBRE NOSOTROS</Link></li>
        </ul>

        <Link to="/" onClick={scrollToUp}>
          <img src={Logo} alt="Logo" className='logo'/>
        </Link>

        <ul>
          <li onClick={scrollToUp}><Link to="/eventos" className='link'>EVENTOS</Link></li>
          <li onClick={scrollToUp}><Link to="/pinatas" className='link'>PIÑATAS</Link></li>
        </ul>

        <div className='responsive-space'>spai</div>
      </div>
      <div className='proximamente'>espaciooo</div>
    </header>
  );
};

export default Header;