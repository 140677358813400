import React from 'react'
import FloatingWhatsApp from 'react-floating-whatsapp';

import logo from "../../assets/images/Loco Circular.png"

import "./whatssApp.scss";

const WhatsApp = () => {
  return (
    <div className='whatsapp-widget'>
      <FloatingWhatsApp phoneNumber="5491136045221" avatar={logo} placeholder="Escribir mensaje" accountName="Mundo de la Dulzura" statusMessage="" chatMessage="Hola! ​👋 En que podemos ayudarle?" allowClickAway={true} allowEsc={true}/>
    </div>
  )
}

export default WhatsApp;